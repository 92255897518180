import React, { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root'
import { Provider, useSelector, useDispatch } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { ConfigProvider } from 'antd'
import store from './redux/store'
import Admin from './routes/admin'
import './static/css/style.css'
import config from './config/config'
import ProtectedRoute from './components/utilities/protectedRoute'
import Login from './container/profile/authentication/overview/SignIn'
import ResetPassword from './container/profile/authentication/overview/NewPassword'
import ForgotPass from './container/profile/authentication/overview/ForgotPassword'
import Validate from './container/profile/authentication/overview/Validate'
import ResetPasswordSuccess from './container/profile/authentication/overview/PasswordResetSuccess';
import ResetPasswordUpdateSuccess from './container/profile/authentication/overview/ResetPasswordUpdateSuccess';
import { handleGetCart } from './redux/store/actionCreator';
import { handleGetAllNotifications } from './redux/notification/actionCreator';
import {
  getUser,
  handleGetAllCrewList,
  HandlegetAllUsers,
  HandleGetAllUsersList,
  HandleGetUserFavs,
} from './redux/users/actionCreator';
import { getShoutOutTypes, HandlegetAllCoreValues } from './redux/shoutouts/actionCreator';
import { handleGetAllApprovalss } from './redux/approvals/actionCreator';

const { theme } = config

const ProviderConfig = () => {
  const { rtl, isLoggedIn, topMenu, darkMode } = useSelector(state => ({
    darkMode: state.ChangeLayoutMode.data,
    rtl: state.ChangeLayoutMode.rtlData,
    topMenu: state.ChangeLayoutMode.topMenu,
    isLoggedIn: !!localStorage.getItem('access_token'),
  }))

  const [path, setPath] = useState(window.location.pathname)
  const [search, setSearch] = useState(window.location.search)

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn){

    dispatch(handleGetAllNotifications())
    dispatch(handleGetAllApprovalss())
    dispatch(handleGetCart());
    dispatch(getUser())
    dispatch(HandlegetAllCoreValues())
    dispatch(handleGetAllCrewList())
    dispatch(HandlegetAllUsers(1))
    dispatch(HandleGetAllUsersList())
    dispatch(HandleGetUserFavs())
    dispatch(getShoutOutTypes())
    }
    let unmounted = false
    if (!unmounted) {
      setPath(window.location.pathname)
      setSearch(window.location.search)
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true)
  }, [path, isLoggedIn])

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider
        theme={{
          ...theme,
          rtl,
          topMenu,
          darkMode,
        }}
      >
        <Router basename={process.env.REACT_APP_PUBLIC_URL}>
          <Switch>
            <ProtectedRoute path="/admin">
              <Admin />
            </ProtectedRoute>

            <Route path="/public/validate">
              <Validate />
            </Route>
            <Route exact path="/forgotPassword" component={ForgotPass} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/password-reset-success" component={ResetPasswordSuccess} />
            <Route exact path="/password-update-success" component={ResetPasswordUpdateSuccess} />
            <Route path="/login">
              <Login />
            </Route>

            <Route path="/">
              <Redirect to="/admin" />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  )
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  )
}

export default hot(App)
