import actions from './actions'

const initialState = {
  allQRCode: {},
  currentQRCode: {},
  generalQRCode: [],
  validatePending: false,
  validateError: false,
  validateSuccess: false,
  validateErrorMessage: '',
  redeemLoading: true,
  redeemSuccess: false,
  redeemError: false,
  redeemAlreadyRedeemed: false,
}
const qrCodeReducer = (state = initialState, action) => {
  const { type, data, err } = action
  let newState
  switch (type) {
    case actions.GET_ALL_QRCODE:
      newState = Object.assign(state.allQRCode, data.data)
      return {
        ...state,
        newState,
      }
    case actions.GENERATE_QRCODE:
      newState = Object.assign(state.currentQRCode, data.data)
      return {
        ...state,
        newState,
      }
    case actions.GET_GENERAL_QRCODE:
      newState = Object.assign(state.generalQRCode, data.data)
      return {
        ...state,
        newState,
      }
    case actions.REDEEM_LOADING:
      return {
        ...state,
        redeemLoading: data,
      }
    case actions.REDEEM_SUCCESS:
      return {
        ...state,
        redeemSuccess: data,
      }
    case actions.REDEEM_ERROR:
      return {
        ...state,
        redeemError: data,
      }
    case actions.ALREADY_REDEEMED:
      return {
        ...state,
        redeemAlreadyRedeemed: data,
      }
    case actions.VALIDATE_QRCODE_PENDING:
      return {
        ...state,
        validatePending: true,
        validateError: false,
        validateErrorMessage: '',
        validateSuccess: false,
      }
    case actions.VALIDATE_QRCODE_SUCCESS:
      return {
        ...state,
        validatePending: false,
        validateError: false,
        validateErrorMessage: '',
        validateSuccess: data,
      }
    case actions.VALIDATE_QRCODE_ERROR:
      return {
        ...state,
        validatePending: false,
        validateSuccess: false,
        validateError: true,
        validateErrorMessage: data
      }



    default:
      return state
  }
}

export { qrCodeReducer }
