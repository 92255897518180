const actions = {
  GET_ALL_APPROVALS: 'GET_ALL_APPROVALS',

  getAllApprovals: result => ({
    type: actions.GET_ALL_APPROVALS,
    data: result,
  }),
}

export default actions
