import React, { useEffect, useState } from 'react'
import { Avatar, Button, Col, message, Row, Typography, Popover } from 'antd'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import FeatherIcon from 'feather-icons-react'
import { InfoWraper, UserDropDwon } from './auth-info-style'
import Notification from './notification'
import Approval from './approvals'
import Settings from './settings'
import { logOut } from '../../../redux/authentication/actionCreator'
import { getUser } from '../../../redux/users/actionCreator'
import Cart from './cart'
import SqrLink from './SqrLink'

const AuthInfo = () => {
  const dispatch = useDispatch()
  const { path } = useRouteMatch()

  const {
    firstName,
    groupsID,
    lastName,
    profileImage,
    positionTitle,
  } = useSelector(
    state => ({
      firstName: state.users.currentUserData.first_name,
      groupsID: state.users.currentUserData.groups_id,
      lastName: state.users.currentUserData.last_name,
      profileImage: state.users.currentUserData.profile_image,
      totalPoints: state.users.currentUserData.total_points,
      pointLimit: state.users.currentUserData.pointLimit,
      currentUserId: state.users.currentUserData.id,
      positionTitle: state.users.currentUserData.position_title,
      crewData: state.users.crewData,
      shoutouts: state.allshoutouts.allShoutOuts.results,
      shoutOutError: state.allshoutouts.allShoutOuts.shoutOutError,
      shoutOutErrorMessage:
        state.allshoutouts.allShoutOuts.shoutOutErrorMessage,
      coreValues: state.allshoutouts.allCoreValue,
    }),
    shallowEqual,
  )

  const [state, setState] = useState({
    flag: 'english',
  })
  const { flag } = state
  const history = useHistory()
  const SignOut = e => {
    dispatch(logOut())
    history.push('/login')
  }

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <Row>
          <Col span={24}>
            <Avatar src={`${profileImage}`} size={60} />
          </Col>
          <Col span={24}>
            <Typography.Title level={5} style={{ marginBottom: '0.4rem' }}>
              {firstName} {lastName}
            </Typography.Title>
            <Typography.Text style={{ color: '#8b8b8b' }}>
              {positionTitle}
            </Typography.Text>
          </Col>
        </Row>
        <Link
          to={`${path}/profile`}
          style={{
            padding: '0',
            alignItems: 'left',
            marginTop: '1rem',
          }}
        >
          <Button type="primary" style={{ width: '100%' }}>
            View Profile
          </Button>
        </Link>
        <ul className="user-dropdwon__links" />
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  )

  const onFlagChangeHandle = value => {
    setState({
      ...state,
      flag: value,
    })
  }

  let error = useSelector(state => {
    return state.error
  })

  useEffect(() => {
    if (error.error.message) {
      message.error(error.error.message)
    }
  }, [error])

  return (
    <InfoWraper>
      <Cart></Cart>
      <SqrLink />
      <Notification />
      {groupsID === 1 ? (
        <>
          <Approval />
          <Settings />
        </>
      ) : (
        <></>
      )}
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src={`${profileImage}`} />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  )
}

export default AuthInfo
