import actions from './actions'
import axiosInstance from '../../axiosApi'
// TODO import getCurrentCrew Action
const {
  getCurrentUser,
  getCurrentCrew,
  searchUsers,
  getAllUsers,
  getAllUserList,
  getAllCrewList,
  getUserById,
  getSeacrhedCrewMembers,
  getcurrentUserFavs,
  getAllInactiveUsersList,
  getAllInactiveUsers,
  createUserLoading,
  createUserSuccess,
  updateUserLoading,
  updateUserSuccess,
  updateUserError,
  createUserError,
  loading,
  editCrewSuccess,
  editCrewError,


} = actions

const getUser = () => async dispatch => {
  axiosInstance
    .get('user/current_user/')
    .then(res => {
      dispatch(getCurrentUser(res))
    })
    .catch(err => {
      return null
    })
}

const HandlegetAllUsers = pageNumber => async dispatch => {
  dispatch(loading(true))
  axiosInstance
    .get(`user/get_all/?page=${pageNumber}`)
    .then(res => {
      dispatch(getAllUsers(res))
      dispatch(loading(false))
    })
    .catch(err => {
      dispatch(loading(false))
      return null
    })
}

const HandleGetAllUsersList = () => async dispatch => {
  axiosInstance
    .get(`/user/get_all_list/`)
    .then(res => {
      dispatch(getAllUserList(res))
    })
    .catch(err => {
      return null
    })
}

const HandleGetAllInactiveUsersList = () => async dispatch => {
  axiosInstance
    .get(`/user/get_all_inactive_list/`)
    .then(res => {
      dispatch(getAllInactiveUsersList(res))
    })
    .catch(err => {
      return null
    })
}

const HandleGetAllInactiveUsers = () => async dispatch => {
  axiosInstance
    .get(`/user/get_all_inactive_users/`)
    .then(res => {
      dispatch(getAllInactiveUsers(res))
    })
    .catch(err => {
      return null
    })
}

const createUser = userObj => async dispatch => {
  dispatch(createUserLoading(true))
  axiosInstance
    .post('user/create/', userObj)
    .then(async res => {
      dispatch(createUserSuccess(true))
      dispatch(HandleGetAllUsersList(res))
      dispatch(HandlegetAllUsers(1))
      dispatch(createUserSuccess(false))
    })
    .catch(error => {
      let error_message_array = error.response.data
      dispatch(createUserError({status: true, messages: error_message_array }))
    })
}

const searchAllUsers = searchText => async dispatch => {
  if (searchText.length > 0) {
    axiosInstance
      .get(`user/search_all_users/?search_text=${searchText}`)
      .then(res => {
        dispatch(searchUsers(res))
      })
      .catch(err => {
        return null
      })
  }
}

const searchAllUsersByID = searchID => async dispatch => {
  if (searchID) {
    axiosInstance
      .get(`user/get_user_by_id/?user_id=${searchID}`)
      .then(res => {
        dispatch(getUserById(res))
      })
      .catch(err => {
        return null
      })
  }
}

const HandleGetUserFavs = () => async dispatch => {
  axiosInstance
    .get(`/user/get_favorites/`)
    .then(res => {
      dispatch(getcurrentUserFavs(res))
    })
    .catch(err => {
      return null
    })
}

const updateUserFavs = newFavArray => async dispatch => {
  axiosInstance
    .put('user/update_favorites/', newFavArray)
    .then(res => {
      dispatch(HandleGetUserFavs())
    })
    .catch(error => {
      return null
    })
}

const deleteUser = userIDObj => async dispatch => {
  axiosInstance
    .put('user/delete_user/', userIDObj)
    .then(res => {
      dispatch(HandleGetAllInactiveUsers());
      dispatch(HandleGetAllUsersList(res));
      dispatch(HandlegetAllUsers(1));
    })
    .catch(error => {
      return null
    })
}

const updateUser = userObj => async dispatch => {
  dispatch(updateUserLoading(true))
  axiosInstance
    .put('user/update_user/', userObj)
    .then(res => {
      dispatch(updateUserSuccess(true))
      dispatch(HandleGetAllUsersList(res))
      dispatch(HandlegetAllUsers(1))
      dispatch(getUser())
      dispatch(updateUserSuccess(false))
    })
    .catch(error => {
      let error_message_array = error.response.data
      dispatch(updateUserError({status: true, messages: error_message_array }))
    })
}

/*
  User Crew Endpoint 
*/

const getCrew = () => async dispatch => {
  dispatch(loading(true))
  axiosInstance
    .get('user/crew/get_crew/')
    .then(res => {

      dispatch(getCurrentCrew(res))
      dispatch(loading(false))
    })
    .catch(err => {
      dispatch(loading(false))
      return null
    })
}

const updateCrewData = newCrewData => async dispatch => {
  dispatch(loading(true))
  axiosInstance
    .put('user/crew/update_crew', newCrewData)
    .then(res => {
      dispatch(editCrewSuccess(true))
      dispatch(handleGetSearchedCrew(res.data.id))

      dispatch(editCrewSuccess(false))
      dispatch(loading(false))
    })
    .catch(error => {
      dispatch(editCrewError({status: true, message: error.response.data}))
    })
}

const handleGetSearchedCrew = crewId => async dispatch => {
  dispatch(loading(true))
  axiosInstance
    .get(`user/crew/search_crew/?crew_id=${crewId}`)
    .then(res => {
      dispatch(getSeacrhedCrewMembers(res))
      dispatch(loading(false))
    })
    .catch(err => {
      dispatch(loading(false))
      return null
    })
}

const createCrew = userObj => async dispatch => {
  dispatch(loading(true))
  axiosInstance
    .post('user/crew/create_crew/', userObj)
    .then(async res => {
      dispatch(editCrewSuccess(true))
      dispatch(handleGetSearchedCrew(res.data.id))

      dispatch(editCrewSuccess(false))
      dispatch(loading(false))
    })
    .catch(error => {
      dispatch(loading(false))
      return null
    })
}


const handleDeleteCrew = crewId => async dispatch => {
  dispatch(loading(true))
  axiosInstance
    .put('user/crew/delete_crew/', {crew_id: crewId})
    .then(async res => {
      dispatch(editCrewSuccess(true))
      dispatch(getUser())
      dispatch(handleGetAllCrewList())
      dispatch(editCrewSuccess(false))
      dispatch(loading(false))
    })
    .catch(error => {
      dispatch(loading(false))
      return null
    })
}

const handleGetAllCrewList = () => async dispatch => {
  axiosInstance
    .get(`user/crew/list_crews/`)
    .then(res => {
      dispatch(getAllCrewList(res))
    })
    .catch(err => {
      return null
    })
}

// TODO Add get currentUserCrew function and export

export {
  getUser,
  searchAllUsers,
  handleGetAllCrewList,
  getCrew,
  createUser,
  HandlegetAllUsers,
  HandleGetAllUsersList,
  handleGetSearchedCrew,
  updateCrewData,
  HandleGetUserFavs,
  updateUserFavs,
  updateUser,
  createCrew,
  searchAllUsersByID,
  deleteUser,
  HandleGetAllInactiveUsers,
  HandleGetAllInactiveUsersList,
  handleDeleteCrew,
  createUserSuccess

}
