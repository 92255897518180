import actions from './actions'
import axiosInstance from '../../axiosApi'
import { getUser } from '../users/actionCreator'

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
} = actions

const login = ({ email, password }) => async dispatch => {
  dispatch(loginBegin())

  axiosInstance
    .post(`/user/token/obtain/`, { email: email, password })
    .then(res => {
      axiosInstance.defaults.headers.Authorization = `JWT ${res.data.access}`
      localStorage.setItem('access_token', res.data.access)
      localStorage.setItem('refresh_token', res.data.refresh)
      // TODO set user information from res.data.user
      dispatch(getUser())
      return dispatch(loginSuccess(true))
    })
    .catch(err => dispatch(loginErr(err)))
}

const logOut = () => async dispatch => {
  dispatch(logoutBegin())
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  axiosInstance.defaults.headers.Authorization = null
  dispatch(logoutSuccess(null))
}

export { login, logOut }
