import React, { useEffect } from 'react'
import { Badge, Popover } from 'antd'
import FeatherIcon from 'feather-icons-react'
import { Link, useHistory } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'
import { useSelector, useDispatch } from 'react-redux'
import ReactTimeAgo from 'react-time-ago/commonjs/ReactTimeAgo'
import { AtbdTopDropdwon } from './auth-info-style'
import Heading from '../../heading/heading'
import { handleGetAllApprovalss } from '../../../redux/approvals/actionCreator'

const ApprovalBox = () => {
  // hooks
  const dispatch = useDispatch()
  const allApprovals = useSelector(state => state.approvals.allApprovals)

  function getApprovalText(type) {
    if (type === 'PointNominationApproval') {
      return 'Point Nomination Approval'
    }
    if (type === 'PointAllocationApproval') {
      return 'Point Allocation Approval'
    }
    if (type === 'UserImageApproval') {
      return 'User Image Approval'
    }
    if (type === 'RedeemShopItem') {
      return 'Shop Item Redemption Approval'
    }
    if (type === 'QRCodeApproval') {
      return 'QR Code Approval'
    }
    return undefined
  }
  function getApprovalLink(type) {
    if (type === 'PointNominationApproval') {
      return '/admin/approvals/nomination'
    }
    if (type === 'PointAllocationApproval') {
      return '/admin/approvals/allocation'
    }
    if (type === 'UserImageApproval') {
      return '/admin/approvals/image'
    }
    if (type === 'RedeemShopItem') {
      return '/admin/approvals/redeem'
    } if (type === 'QRCodeApproval') {
      return '/admin/approvals/qrcode'
    }
    return undefined
  }

  const history = useHistory()
  // useEffect(() => {
  //   console.log(Object.values(allNotifications).length);
  // }, [])

  const { rtl } = useSelector(state => ({
    rtl: state.ChangeLayoutMode.rtlData,
  }))

  function handleReadApproval(id, approvalLink) {
    history.push(approvalLink)
  }

  const RenderNotificationModel = ({ data }) => (
    <li>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleReadApproval(data.id, getApprovalLink(data.type))
        }}
      >
        <div className="atbd-top-dropdwon__content notifications">
          <div className="notification-icon bg-secondary">
            <FeatherIcon icon="check-circle" />
          </div>
          <div className="notification-content d-flex">
            <div className="notification-text">
              <Heading as="h5">{getApprovalText(data.type)}</Heading>
              <p>
                <ReactTimeAgo
                  date={data.approval_ptr.created_datetime}
                  locale="en-US"
                />
              </p>
            </div>

            <div className="notification-status">
              <Badge dot />
            </div>
          </div>
        </div>
      </div>
    </li>
  )

  const content = (
    <AtbdTopDropdwon className="atbd-top-dropdwon">
      <Heading as="h5" className="atbd-top-dropdwon__title">
        <span className="title-text">Approvals</span>
        <Badge
          className="badge-success"
          count={
            allApprovals
              ? Object.values(
                  allApprovals.filter(item => {
                    if(item) {return item.approval_ptr.status === 1}
                  }),
                ).length
              : ''
          }
        />
      </Heading>
      <Scrollbars autoHide autoHeight autoHeightMax={250}>
        <ul className="atbd-top-dropdwon__nav notification-list">
          {allApprovals
            .filter(item => {
              if(item) {return item.approval_ptr.status === 1}
            })
            .map((item, index) => (
              <div id={index} key={`approval-${index}`}>
                <RenderNotificationModel
                  key={`approval1-${index}`}
                  data={item}
                />
              </div>
            ))}
        </ul>
      </Scrollbars>
    </AtbdTopDropdwon>
  )

  return (
    <div className="notification">
      <Popover placement="bottomLeft" content={content} action="click">
        <Badge
          size="default"
          count={
            allApprovals
              ? Object.values(
                  allApprovals.filter(item => {
                    if(item) {return item.approval_ptr.status === 1}
                  }),
                ).length
              : ''
          }
          overflowCount={9}
          offset={[-8, -5]}
        >
          <Link to="#" className="head-example">
            <FeatherIcon icon="user-check" size={20} />
          </Link>
        </Badge>
      </Popover>
    </div>
  )
}

export default ApprovalBox
