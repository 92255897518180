import actions from './actions'

const initialState = {
  current_user_crew:  {
      crew_name: '',
      crew_members: [],
      crew_points: 0,
      crew_foreman_data: {
            "id": undefined,
            "email": "",
            "username": "",
            "first_name": "",
            "last_name": "",
            "position_title": "",
            "preferred_name": "",
            "shout_out_count": 0,
            "profile_image": "",
            "is_active": true
          },
      crew_id: -99,
      crew_rank: 0,
  },
  current_visible_crew: {
      crew_name: '',
      crew_members: [],
      crew_points: 0,
      crew_foreman_data: {
            "id": undefined,
            "email": "",
            "username": "",
            "first_name": "",
            "last_name": "",
            "position_title": "",
            "preferred_name": "",
            "shout_out_count": 0,
            "profile_image": "",
            "is_active": true
          },
      crew_id: -99,
      crew_rank: 0,
  },
  currentUserData: {
    id: 0,
    first_name: '',
    last_name: '',
    total_points: 0,
    position_title: '',
    point_limit: 0,
    groups_id: 0,
    profile_image: '/media',
    shout_out_count: 0,
    next_expiring_date: '',
    total_points_expiring: 0,
  },

  allUser: {
    results: [],
    count: 0,
  },
  allInactiveUsers: [],
  allUserList: [],
  allInactiveUsersList: [],
  searchedUserList: [],
  allCrewList: [],
  createUserLoading: false,
  createUserSuccess: false,
  createUserError: false,
  createUserErrorMessages: [],
  updateUserLoading: false,
  updateUserSuccess: false,
  updateUserError: false,
  updateUserErrorMessages: [],
  loading: false,
  success: false,
  editCrewError: false,
  editCrewErrorMessage: '',
  userFavs: [],
}
const userReducer = (state = initialState, action) => {
  const { type, data, err } = action
  let newState
  let current_user_crew = initialState.current_user_crew;
  switch (type) {
    case actions.GET_CURRENT_USER:
        current_user_crew =  {
          crew_name: data.data.user_crew.crew_name,
          crew_members: data.data.user_crew.crew_members,
          crew_points: data.data.user_crew.crew_points,
          crew_foreman_data: data.data.user_crew.crew_foreman != null ? data.data.user_crew.crew_foreman :
            initialState.current_user_crew.crew_foreman_data,
          crew_id: data.data.user_crew.id,
          crew_rank: data.data.user_crew.crew_rank,
      }
      return {
        ...state,
        current_user_crew:
            current_user_crew,
        current_visible_crew:
            current_user_crew,
        currentUserData: {
          ...data.data,
        },
      }


    case actions.GET_CURRENT_CREW:
      let newData
      if (data.data.length > 0) {
        newData = data.data[0]
      } else {
        newData = data.data
      }
      newState =
        Object.values(newData).length > 0
          ? Object.assign(state.crewData, {
              crew_name: newData.crew_name,
              crew_members: newData.crew_members,
              crew_foreman_id: newData.crew_foreman && newData.crew_foreman.id,
              crew_points: newData.crew_points,
              crew_foreman_data: newData.crew_foreman,
              crew_id: newData.id,
              crew_rank: newData.crew_rank,
            })
          : (newState = Object.assign(state.crewData, {}))
      return {
        ...state,
        newState,
      }
    case actions.GET_ALL_USERS:
      newState = Object.assign(state.allUser, data.data)
      return {
        ...state,
        newState,
      }
    case actions.GET_ALL_INACTIVE_USERS:
      newState = Object.assign(state.allInactiveUsers, data.data)
      return {
        ...state,
        allInactiveUsers: newState,
      }
    case actions.GET_ALL_USERS_LIST:
      newState = Object.assign(state.allUserList, data.data)
      return {
        ...state,
        allUserList: newState,
      }

    case actions.GET_ALL_INACTIVE_USERS_LIST:
      newState = Object.assign(state.allInactiveUsersList, data.data)
      return {
        ...state,
        allInactiveUsersList: newState,
      }
    case actions.SEARCH_USERS:
      newState = data.data
      return {
        ...state,
        searchedUserList: newState,
      }
    case actions.GET_ALL_CREW_LIST:
      newState = Object.assign(state.allCrewList, data.data)
      return {
        ...state,
        newState,
      }
    case actions.GET_SEARCHED_CREW_MEMBERS:

       current_user_crew =  {
        crew_name: data.data.crew_name,
        crew_members: data.data.crew_members,
        crew_points: data.data.crew_points,
        crew_foreman_data: data.data.crew_foreman != null ? data.data.crew_foreman :
          initialState.current_user_crew.crew_foreman_data,
        crew_id: data.data.id,
        crew_rank: data.data.crew_rank,
      }
      return {
        ...state,
        current_visible_crew:
          current_user_crew

      }
    case actions.GET_USER_BY_ID:
      newState = Object.assign(state.searchedUserByID, data.data)
      return {
        ...state,
        newState,
      }
    case actions.GET_USER_FAVS:
      newState = Object.assign(state.userFavs, data.data)
      return {
        ...state,
        userFavs: newState,
      }
      case actions.CREATE_USER_LOADING:
      return {
        ...state,
        createUserLoading: true,
        createUserSuccess: false,
        createUserError: false,
        createUserErrorMessages: []
      }
      case actions.CREATE_USER_ERROR:
      return {
        ...state,
        createUserLoading: false,
        createUserSuccess: false,
        createUserError: data.status,
        createUserErrorMessages: data.messages,
      }
      case actions.CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserLoading: false,
        createUserSuccess: data,
        createUserError: false,
        createUserErrorMessages: [],
      }
      case actions.UPDATE_USER_LOADING:
      return {
        ...state,
        updateUserLoading: data,
        updateUserSuccess: false,
        updateUserError: false,
        updateUserErrorMessages: []
      }
      case actions.UPDATE_USER_ERROR:
      return {
        ...state,
        updateUserLoading: false,
        updateUserSuccess: false,
        updateUserError: data.status,
        updateUserErrorMessages: data.messages,
      }
      case actions.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserLoading: false,
        updateUserSuccess: data,
        updateUserError: false,
        updateUserErrorMessages: [],
      }
      case actions.CREW_LOADING:
      return {
        ...state,
        loading: data,
        editCrewError: false,
        editCrewErrorMessage: ''
      }
      case actions.EDIT_CREW_ERROR:
      return {
        ...state,
        loading: false,
        editCrewSuccess: false,
        editCrewError: data.status,
        editCrewErrorMessage: data.message,
      }
      case actions.EDIT_CREW_SUCCESS:
      return {
        ...state,
        loading: false,
        editCrewSuccess: data,
        editCrewError: false,
        editCrewErrorMessage: '',
      }


    default:
      return state
  }
}

export { userReducer }
