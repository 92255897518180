import actions from './actions'
import axiosInstance from '../../axiosApi'
import { getUser } from '../users/actionCreator'
import { handleUpdateError } from '../errorHandling/actionCreator'
import { handleGetAllApprovalss } from '../approvals/actionCreator';

const {
  getallshoutouts,
  getAllShoutOuTtypes,
  getAllCoreValues,
  setShoutOutError,
  getShoutOUtPostById,
  getallshoutoutsLoading,
  getallshoutoutsSuccess,
  getallshoutoutsError,
  groupShoutOutLoading,
  groupShoutOutSuccess,
  groupShoutOutError,
} = actions

const getAllShoutOuts = pagination => async dispatch => {
  dispatch(getallshoutoutsLoading(true))
  axiosInstance
    .get(
      pagination
        ? `shout_out/get_all_shout_outs/?page=${pagination}`
        : 'shout_out/get_all_shout_outs/',
    )
    .then(res => {
      dispatch(getallshoutouts(res))
        dispatch(getallshoutoutsLoading(false))
      if (res.status = 200){
        dispatch(getallshoutoutsError(false))
        dispatch(getallshoutoutsSuccess(true))
      }else{
        dispatch(getallshoutoutsError(true))
      }

    })
    .catch(error => {
      dispatch(getallshoutoutsError(true))
      dispatch(
        handleUpdateError(
          'Error in fetching shoutouts, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const HandleGetShoutOutById = id => async dispatch => {
  axiosInstance
    .get(`/shout_out/get_shout_out_post_by_id/?post_id=${id}`)
    .then(res => {
      dispatch(getShoutOUtPostById(res))
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in getting Shoutout Type By ID, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const getShoutOutTypes = () => async dispatch => {
  axiosInstance
    .get('shout_out/get_all_shoutout_types/')
    .then(result => {
      dispatch(getAllShoutOuTtypes(result))
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in getting Shoutout Types, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const deleteShoutOutType = deleteData => async dispatch => {
  axiosInstance
    .delete(`shout_out/delete_shout_out_type/`, { data: deleteData })
    .then(res => {
      dispatch(getShoutOutTypes())
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in deleting Shoutout Type, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const HandlegetAllCoreValues = () => async dispatch => {
  axiosInstance
    .get('shout_out/get_all_core_values/')
    .then(res => {
      dispatch(getAllCoreValues(res))
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in getting core-values, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const createShoutOut = shoutoutObj => async dispatch => {
  axiosInstance
    .post('shout_out/create_post/', shoutoutObj)
    .then(async res => {
      if (res.status === 200) {
        dispatch(getUser())
        dispatch(getAllShoutOuts(1))
        if (shoutoutObj.is_nominated){
          dispatch(handleGetAllApprovalss())
        }
      } else {
        dispatch(setShoutOutError([res.data[0], true]))
        dispatch(getUser())
        dispatch(getAllShoutOuts(1))
      }
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in creating shoutout, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const createBulkShoutOut = shoutoutObj => async dispatch => {
  dispatch(groupShoutOutLoading(true))
  axiosInstance
    .post('shout_out/create_post_group/', shoutoutObj)
    .then(async res => {
      dispatch(groupShoutOutLoading(false))
      dispatch(groupShoutOutSuccess(true))
      dispatch(getUser())
      dispatch(getAllShoutOuts(1))
      dispatch(groupShoutOutSuccess(false))
    })
    .catch(error => {
      dispatch(groupShoutOutLoading(false))
      dispatch(groupShoutOutError({status: true, message: error.response.statusText}))
      dispatch(groupShoutOutError({status: false, message: ''}))
    })
}

const addShoutOutComment = (commentObj, currentPage) => async dispatch => {
  axiosInstance
    .post('shout_out/add_comment/', commentObj)
    .then(res => {
      dispatch(getAllShoutOuts(currentPage))
      dispatch(HandleGetShoutOutById(res.data.shout_out_id))

    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error adding comment, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const handleCreateShoutOutType = shoutOutTypeObj => async dispatch => {
  axiosInstance
    .post('shout_out/create_shout_out_type/', shoutOutTypeObj)
    .then(res => {
      dispatch(getShoutOutTypes())
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in creating Shoutout Type, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const addLike = (likeObjects, currentPage) => async dispatch => {
  axiosInstance
    .post('shout_out/add_like/', likeObjects)
    .then(res => {
      dispatch(getAllShoutOuts(currentPage))
       dispatch(HandleGetShoutOutById(res.data.shout_out_id))
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in adding like, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const removeLike = (likeObjects, currentPage) => async dispatch => {
  axiosInstance
    .delete('shout_out/remove_like/', { data: likeObjects })
    .then(res => {
      dispatch(getAllShoutOuts(currentPage))
      dispatch(HandleGetShoutOutById(res.data))
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in deleting like, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const deleteCoreValue = coreValueID => async dispatch => {
  axiosInstance
    .delete('shout_out/delete_core_value/', { data: coreValueID })
    .then(res => {
      dispatch(HandlegetAllCoreValues())
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in deleting core value, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const createCoreValue = coreValueObj => async dispatch => {
  axiosInstance
    .post('shout_out/create_core_value/', coreValueObj)
    .then(async res => {
      if (res.status === 200) {
        dispatch(getAllCoreValues())
      } else {
        // handle error here
      }
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in creating core value, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const deleteComment = (commentObj, currentPage) => async dispatch => {
  axiosInstance
    .delete('shout_out/remove_comment/', { data: commentObj })
    .then(res => {
      dispatch(getAllShoutOuts(currentPage))
      dispatch(HandleGetShoutOutById(res.data))
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in deleting core value, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const editComment = (newCommentObj, currentPage) => async dispatch => {
  axiosInstance
    .put('shout_out/edit_comment/', newCommentObj)
    .then(res => {
      dispatch(getAllShoutOuts(currentPage))
      dispatch(HandleGetShoutOutById(res.data.shout_out_id))
    })
    .catch(error => null)
}

const updateCoreValue = newCoreValueObj => async dispatch => {
  axiosInstance
    .put('shout_out/update_core_value/', newCoreValueObj)
    .then(res => {
      dispatch(HandlegetAllCoreValues())
    })
    .catch(error => null)
}

const updateAwards = newCommentObj => async dispatch => {
  axiosInstance
    .put('shout_out/update_shout_out_type/', newCommentObj)
    .then(res => {
      dispatch(getShoutOutTypes())
    })
    .catch(error => null)
}

const resetError = (msg, state) => async dispatch => {
  dispatch(setShoutOutError([msg, state]))
}

export {
  getAllShoutOuts,
  getShoutOutTypes,
  removeLike,
  createShoutOut,
  editComment,
  addShoutOutComment,
  addLike,
  deleteComment,
  HandlegetAllCoreValues,
  updateAwards,
  createBulkShoutOut,
  resetError,
  updateCoreValue,
  deleteCoreValue,
  createCoreValue,
  handleCreateShoutOutType,
  deleteShoutOutType,
  HandleGetShoutOutById,
}
