import React, { Suspense } from 'react'
import { Spin } from 'antd'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Dashboard from './dashboard'
import Pages from './pages'
import withAdminLayout from '../../layout/withAdminLayout'
import Redeem from '../../container/profile/authentication/overview/Redeem'
import ProtectedRoute from '../../components/utilities/protectedRoute'

const Admin = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path="" component={Dashboard} />
        <Route exact path="/admin/redeem">
          <Redeem />
        </Route>
        <Route path={`${path}`} component={Pages} />
      </Suspense>
    </Switch>
  )
}

export default withAdminLayout(Admin)
