const actions = {
  // TODO Add current crew action string variable
  GET_CURRENT_CREW: 'GET_CURRENT_CREW',
  GET_CURRENT_USER: 'GET_CURRENT_USER',
  GET_ALL_USERS: 'GET_ALL_USERS',
  GET_ALL_INACTIVE_USERS: 'GET_ALL_INACTIVE_USERS',
  GET_ALL_USERS_LIST: 'GET_ALL_USERS_LIST',
  GET_ALL_INACTIVE_USERS_LIST: 'GET_ALL_INACTIVE_USERS_LIST',
  SEARCH_USERS: 'SEARCH_USERS',
  GET_ALL_CREW_LIST: 'GET_ALL_CREW_LIST',
  GET_SEARCHED_CREW_MEMBERS: 'GET_SEARCHED_CREW_MEMBERS',
  GET_USER_FAVS: 'GET_USER_FAVS',
  GET_USER_BY_ID: 'GET_USER_BY_ID',
  CREATE_USER_LOADING: 'CREATE_USER_LOADING',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  UPDATE_USER_LOADING: 'UPDATE_USER_LOADING',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  CREW_LOADING: 'CREW_LOADING',
  EDIT_CREW_SUCCESS: 'EDIT_CREW_SUCCESS',
  EDIT_CREW_ERROR: 'EDIT_CREW_ERROR',


  // TODO add current crew action function
  getCurrentUser: result => ({
    type: actions.GET_CURRENT_USER,
    data: result,
  }),
  getCurrentCrew: result => ({
    type: actions.GET_CURRENT_CREW,
    data: result,
  }),
  getAllUsers: result => ({
    type: actions.GET_ALL_USERS,
    data: result,
  }),

  getAllInactiveUsers: result => ({
    type: actions.GET_ALL_INACTIVE_USERS,
    data: result,
  }),

  getAllInactiveUsersList: result => ({
    type: actions.GET_ALL_INACTIVE_USERS_LIST,
    data: result,
  }),
  getAllUserList: result => ({
    type: actions.GET_ALL_USERS_LIST,
    data: result,
  }),
  searchUsers: result => ({
    type: actions.SEARCH_USERS,
    data: result,
  }),
  getAllCrewList: result => ({
    type: actions.GET_ALL_CREW_LIST,
    data: result,
  }),
  getSeacrhedCrewMembers: result => ({
    type: actions.GET_SEARCHED_CREW_MEMBERS,
    data: result,
  }),
  getcurrentUserFavs: result => ({
    type: actions.GET_USER_FAVS,
    data: result,
  }),
  getUserById: result => ({
    type: actions.GET_USER_BY_ID,
    data: result,
  }),createUserLoading: result => ({
    type: actions.CREATE_USER_LOADING,
    data: result,
  }),createUserSuccess: result => ({
    type: actions.CREATE_USER_SUCCESS,
    data: result,
  }),createUserError: (result) => ({
    type: actions.CREATE_USER_ERROR,
    data: result,
  }),updateUserLoading: result => ({
    type: actions.UPDATE_USER_LOADING,
    data: result,
  }),updateUserSuccess: result => ({
    type: actions.UPDATE_USER_SUCCESS,
    data: result,
  }),updateUserError: (result) => ({
    type: actions.UPDATE_USER_ERROR,
    data: result,
  }),
  loading: result => ({
    type: actions.CREW_LOADING,
    data: result,
  }),editCrewSuccess: result => ({
    type: actions.EDIT_CREW_SUCCESS,
    data: result,
  }),editCrewError: (result) => ({
    type: actions.EDIT_CREW_ERROR,
    data: result,

  }),

}

export default actions
