import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import {Tooltip} from 'antd';

const SqrLink = () => {
  const {path} = useRouteMatch();
  return (
    <div className="settings">
      <Tooltip title='Site Observation Report' placement='bottomLeft'>
        <a style={{color: '#B09797'}} href='https://safety.hcssapps.com/SafetyObservation/CreateSafetyObservation?companyID=7f774fee-178a-4601-bf38-4727cf42e14a&businessUnitID=c3047d1a-52ab-413f-a156-9b4f451e2bc6#MainPageView' target='_blank' className="sqrlink">
          <FeatherIcon icon="link" size={20} />
        </a>
      </Tooltip>
    </div>
  )
}

export default SqrLink;
