const actions = {
  GET_ALL_QRCODE: 'GET_ALL_QRCODES',
  GET_GENERAL_QRCODE: 'GET_GENERAL_QRCODE',
  GENERATE_QRCODE: 'GENERATE_QRCODE',
  VALIDATE_QRCODE_USER: 'VALIDATE_QRCODE_USER',
  VALIDATE_QRCODE_PENDING: 'VALIDATE_QRCODE_PENDING',
  VALIDATE_QRCODE_SUCCESS: 'VALIDATE_QRCODE_SUCCESS',
  VALIDATE_QRCODE_ERROR: 'VALIDATE_QRCODE_ERROR',
  REDEEM_LOADING: 'REDEEM_LOADING',
  REDEEM_SUCCESS: 'REDEEM_SUCCESS',
  REDEEM_ERROR: 'REDEEM_ERROR',
  ALREADY_REDEEMED: 'ALREADY_REDEEMED',

  getallQRCode: result => ({
    type: actions.GET_ALL_QRCODE,
    data: result,
  }),
  setRedeemLoading: status => ({
    type: actions.REDEEM_LOADING,
    data: status,
  }),
  setValidatePending: status => ({
    type: actions.VALIDATE_QRCODE_PENDING,
    data: status,
  }),
  setValidateSuccess: status => ({
    type: actions.VALIDATE_QRCODE_SUCCESS,
    data: status,
  }),
  setValidateError: status => ({
    type: actions.VALIDATE_QRCODE_ERROR,
    data: status,
  }),
  setRedeemSuccess: status => ({
    type: actions.REDEEM_SUCCESS,
    data: status,
  }),
  setRedeemError: status => ({
    type: actions.REDEEM_ERROR,
    data: status,
  }),
  setAlreadyRedeemed: status => ({
    type: actions.ALREADY_REDEEMED,
    data: status,
  }),
  generateQRCode: result => ({
    type: actions.GENERATE_QRCODE,
    data: result,
  }),
  validateQRCodeUser: result => ({
    type: actions.VALIDATE_QRCODE_USER,
    data: result,
  }),
  getGeneralQRCode: result => ({
    type: actions.GET_GENERAL_QRCODE,
    data: result,
  }),
}

export default actions
