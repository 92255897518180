import actions from './actions'
import axiosInstance from '../../axiosApi'
import { handleUpdateError } from '../errorHandling/actionCreator'
import { handleGetAllApprovalss } from '../approvals/actionCreator';
import { getUser } from '../users/actionCreator'

const { getAllItems, getAllPurchases, getCartItems } = actions

const handleGetAllItems = () => async dispatch => {
  axiosInstance
    .get(`store/get_all_items/?timestamp=${new Date().getTime()}`)
    .then(res => {
      dispatch(getAllItems(res))
    })
    .catch(error => {
      dispatch(handleUpdateError('Error in fetching shop items'))
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const handleGetAllPurchases = () => async dispatch => {
  axiosInstance
    .get(`store/get_all_purchases/`)
    .then(result => {
      dispatch(getAllPurchases(result))
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in fetching all your purchases, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const handleReedemPurchase = purchaseData => async dispatch => {
  axiosInstance
    .put('store/redeem_purchase/', purchaseData)
    .then(async res => {})
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in redeeming your purchases, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const handleGetCart = () => async dispatch => {
  axiosInstance
    .get(`store/get_cart/`)
    .then(result => {
      dispatch(getCartItems(result))
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in getting your cart items, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const handleCreateItem = itemData => async dispatch => {
  axiosInstance
    .post('store/create_item/', itemData)
    .then(result => {
      dispatch(handleGetAllItems())
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in creating shop item, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const handleAddItemToCart = itemData => async dispatch => {
  axiosInstance
    .post('store/create_cart/', itemData)
    .then(result => {
      dispatch(getUser())
      dispatch(handleGetCart())
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in adding item to your cart, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const handleMakePurchase = itemData => async dispatch => {
  axiosInstance
    .post('store/make_purchase/', itemData)
    .then(result => {
      dispatch(handleGetCart())
      dispatch(handleGetAllApprovalss())
      dispatch(getUser())
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in making purchases, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const handleUpdateCart = itemData => async dispatch => {
  axiosInstance
    .put('store/update_cart/', itemData)
    .then(async res => {
      dispatch(getUser())
      dispatch(handleGetCart())
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in updating your cart, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const handleDeleteItem = itemData => async dispatch => {
  axiosInstance
    .delete('store/delete_item/', { data: itemData })
    .then(async res => {
      dispatch(handleGetCart())
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in deleting shop item, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const handleUpdateItem = itemData => async dispatch => {
  axiosInstance
    .put('store/update_item/', itemData)
    .then(async res => {
      dispatch(handleGetAllItems())
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in updating shop item, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}
const HandleDeleteStoreItem = id => async dispatch => {
  axiosInstance
    .delete('store/delete_store_item/', { data: { item_id: id } })
    .then(async res => {
      dispatch(handleGetAllItems())
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error in updating shop item, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

export {
  handleGetAllItems,
  handleGetAllPurchases,
  handleReedemPurchase,
  handleGetCart,
  handleCreateItem,
  handleAddItemToCart,
  handleMakePurchase,
  handleUpdateCart,
  handleDeleteItem,
  handleUpdateItem,
  HandleDeleteStoreItem,
}
