import actions from './actions'

const initialState = {
  allNotifications: [],
}

const NotificationReducer = (state = initialState, action) => {
  const { type, data, err } = action
  switch (type) {
    case actions.GET_ALL_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: [...data.data]
      }
      break
    default:
      return state
  }
}

export { NotificationReducer }
