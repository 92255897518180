import React, { useEffect } from 'react'
import { Badge, Popover } from 'antd'
import FeatherIcon from 'feather-icons-react'
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
import { useSelector, useDispatch } from 'react-redux'
import ReactTimeAgo from 'react-time-ago/commonjs/ReactTimeAgo'
import { AtbdTopDropdwon } from './auth-info-style'
import Heading from '../../heading/heading'
import {
  handleGetAllNotifications,
  handleReadNotification,
} from '../../../redux/notification/actionCreator'

const NotificationBox = () => {
  // hooks
  const dispatch = useDispatch()
  const allNotifications = useSelector(
    state => state.notification.allNotifications,
  )


  const { rtl } = useSelector(state => ({
    rtl: state.ChangeLayoutMode.rtlData,
  }))
  const history = useHistory()

  function handleClickNotification(data) {
    history.push('/admin/profile/notification')
    dispatch(handleReadNotification(data.id))
  }
  const RenderNotificationModel = ({ data }) => (
    <li>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleClickNotification(data)
        }}
      >
        <div className="atbd-top-dropdwon__content notifications">
          <div className="notification-icon bg-secondary">
            <FeatherIcon icon="bell" />
          </div>
          <div className="notification-content d-flex">
            <div className="notification-text">
              <Heading as="h5">{data.notification_text}</Heading>
              <p>
                <ReactTimeAgo
                  date={data.notification_time}
                  locale="en-US"
                />
              </p>
            </div>

            <div className="notification-status">
              <Badge dot />
            </div>
          </div>
        </div>
      </div>
    </li>
  )

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    }
    return <div style={{ ...style, ...thumbStyle }} props={props} />
  }

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    }
    return <div className="hello" style={thumbStyle} />
  }

  const renderView = ({ style, ...props }) => {
    const customStyle = {
      marginRight: rtl && 'auto',
      [rtl ? 'marginLeft' : 'marginRight']: '-17px',
    }
    return <div {...props} style={{ ...style, ...customStyle }} />
  }

  renderThumb.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  }

  renderView.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  }

  const content = (
    <AtbdTopDropdwon className="atbd-top-dropdwon">
      <Heading as="h5" className="atbd-top-dropdwon__title">
        <span className="title-text">Notifications</span>
        <Badge
          className="badge-success"
          count={
            allNotifications &&
            Object.values(
              allNotifications.filter(item => {
                return item.has_read !== true
              }),
            ).length
          }
        />
      </Heading>
      <Scrollbars
        autoHeight
        autoHide
        renderThumbVertical={renderThumb}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
      >
        <ul className="atbd-top-dropdwon__nav notification-list">
          {Object.values(allNotifications).length > 0 &&
            Object.values(allNotifications)
              .filter(item => {
                return item.has_read !== true
              })
              .map((item, index) => (
                <RenderNotificationModel key={index} data={item} />
              ))}
        </ul>
      </Scrollbars>
    </AtbdTopDropdwon>
  )

  return (
    <div className="notification">
      <Popover placement="bottomLeft" content={content} action="click">
        <Badge
          count={
            allNotifications
              ? Object.values(
                  allNotifications.filter(item => {
                    return item.has_read === false
                  }),
                ).length
              : ''
          }
          offset={[-8, -5]}
          overflowCount={9}
        >
          <Link to="#" className="head-example">
            <FeatherIcon icon="bell" size={20} />
          </Link>
        </Badge>
      </Popover>
    </div>
  )
}

export default NotificationBox
