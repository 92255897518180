import React, { useCallback, useEffect, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import { Badge } from 'antd'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'


const Cart = () => {
  let cartItems = useSelector(state => {
    return state.storeData.allCartItems
  })

  const dispatch = useDispatch()

  const { path } = useRouteMatch()
  return (
    <div className="settings">
      <Badge
        count={cartItems.length}
        size="default"
        overflowCount={9}
        offset={[-8, -5]}
      >

        <Link to={`${path}/cart`} className="head-example">
          <FeatherIcon icon="shopping-cart" size={20} />
        </Link>
      </Badge>
    </div>
  )
}

export default Cart
