import React from 'react'
import FeatherIcon from 'feather-icons-react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Row, Col, Popover } from 'antd'
import {
  UserOutlined,
  UsergroupAddOutlined,
  ShoppingCartOutlined,
  GiftOutlined
} from '@ant-design/icons'
import { SettingDropdwon } from './auth-info-style'
import Heading from '../../heading/heading'

const Settings = () => {
  const {path} = useRouteMatch();
  const content = (
    <SettingDropdwon>
      <div className="setting-dropdwon">
        <Row gutter="10">
          <Col sm={12}>
            <Link to={`${path}/usermanagement`}>
              <figure className="setting-dropdwon__single d-flex">
                <figcaption>
                  <Heading as="h3">
                    <UserOutlined
                      style={{fontSize: '23px', background:'#EC1C24', color:'#fff', borderRadius: '90px', padding: '9px'}}
                    />
                  </Heading>
                  <p>User Management</p>
                </figcaption>
              </figure>
            </Link>
          </Col>
          <Col sm={12}>
          <Link to={`${path}/pointsmanagement`}>
            <figure     
              className="setting-dropdwon__single d-flex"
            >
              <figcaption>
                <Heading as="h3">
                  <UserOutlined
                    style={{fontSize: '23px', background:'#EC1C24', color:'#fff', borderRadius: '90px', padding: '9px'}}
                  />
                </Heading>
                <p>Points Managment</p>
              </figcaption>
            </figure>
          </Link>
          </Col>
          <Col sm={12}>
            <Link to={`${path}/crewmanagement`}>
              <figure     
                className="setting-dropdwon__single d-flex"
              >
                <figcaption>
                  <Heading as="h3">
                    <UsergroupAddOutlined
                      style={{fontSize: '23px', background:'#EC1C24', color:'#fff', borderRadius: '90px', padding: '9px'}}
                    />
                  </Heading>
                  <p>Crew Management</p>
                </figcaption>
              </figure>
            </Link>
          </Col>
          <Col sm={12}>
          <Link to={`${path}/shopmanagement`}>
            <figure className="setting-dropdwon__single d-flex">
              <figcaption>
                <Heading as="h3">
                  <ShoppingCartOutlined
                    style={{fontSize: '23px', background:'#EC1C24', color:'#fff', borderRadius: '90px', padding: '9px'}}
                  />
                </Heading>
                <p>Shop Management</p>
              </figcaption>
            </figure>
          </Link>
          </Col>
          <Col sm={12}>
            <Link to={`${path}/awardmanagement`}>
              <figure className="setting-dropdwon__single d-flex">
                <figcaption>
                  <Heading as="h3">
                    <GiftOutlined
                      style={{fontSize: '23px', background:'#EC1C24', color:'#fff', borderRadius: '90px', padding: '9px'}}
                    />
                  </Heading>
                  <p>Award Management</p>
                </figcaption>
              </figure>
            </Link>
          </Col>
        </Row>
      </div>
    </SettingDropdwon>
  )

  return (
    <div className="settings">
      <Popover placement="bottomRight" content={content} action="click">
        <Link to="#" className="head-example">
          <FeatherIcon icon="settings" size={20} />
        </Link>
      </Popover>
    </div>
  )
}

export default Settings
