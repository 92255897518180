import actions from './actions'
import axiosInstance from '../../axiosApi'

const { getAllNotification } = actions

const handleGetAllNotifications = () => async dispatch => {
  axiosInstance
    .get('notification/get_all/')
    .then(res => {
      dispatch(getAllNotification(res))
    })
    .catch(err => null)
}

const handleReadNotification = notificationId => async dispatch => {
  axiosInstance
    .get(`notification/change_read_status?notification_id=${notificationId}`)
    .then(res => {
      dispatch(handleGetAllNotifications())
    })
    .catch(err => null)
}

const handleDeleteNotification = notificationId => async dispatch => {
  axiosInstance
    .get(`notification/delete?notification_id=${notificationId}`)
    .then(res => {
      dispatch(handleGetAllNotifications())
    })
    .catch(err => null)
}

const handleDeleteManyNotifications = notifications => async dispatch => {
  axiosInstance
    .post('notification/delete_many', {"notifications": notifications})
    .then(res => {
      dispatch(handleGetAllNotifications())
    })
    .catch(err => null)
}


export { handleGetAllNotifications, handleReadNotification, handleDeleteNotification, handleDeleteManyNotifications }
