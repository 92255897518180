import React, { useState, useEffect } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { Form, Input, Button, message, Checkbox, Row, Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
// eslint-disable-next-line import/no-extraneous-dependencies
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons'
import { AuthWrapper } from './style'
import AuthLayout from '../Index'
import { login } from '../../../../redux/authentication/actionCreator'
import Heading from '../../../../components/heading/heading'

const SignIn = () => {
  const [showLoginError, setShowLoginError] = useState(true)
  const history = useHistory()
  const dispatch = useDispatch()
  const loginError = useSelector(state => state.auth.error)
  const isLoading = useSelector(state => state.auth.loading)
  const isLoggedIn = useSelector(state => state.auth.login)
  const [form] = Form.useForm()
  const lastLocation = useLocation()
  const [state, setState] = useState({
    checked: null,
  })
  const [credentials, setCredentials] = useState({
    email: 'test@test.com',
    password: '',
  })

  useEffect(() => {
    loginError &&
      message.error(
        'Incorrect password or email, please check and try again',
      )
  }, [loginError])
  useEffect(() => {
    const lastLocationPath = lastLocation.state && lastLocation.state.from
    if (isLoggedIn) {
      if (lastLocationPath) {
        history.push(`${lastLocationPath.pathname}${lastLocationPath.search}`)
      } else {
        history.push('/admin')
      }
    }
  }, [history, isLoggedIn])

  const handleSubmit = event => {
    // TODO add username and password
    if (credentials.email && credentials.password) {
      dispatch(login(credentials))
    }
  }

  const onChange = checked => {
    setState({ ...state, checked })
  }

  const credentialChange = e => {
    setShowLoginError(false)
    if (e.target.dataset.credentials === 'email') {
      setCredentials({
        ...credentials,
        email: `${e.target.value.toLowerCase()}`,
      })
    } else if (e.target.dataset.credentials === 'password') {
      setCredentials({ ...credentials, password: `${e.target.value}` })
    }
  }
  return (
    <AuthWrapper>
      <Row>
        <Col span={24}>
          <div className="auth-contents" style={{ paddingTop: '25%' }}>
            <Form
              name="login"
              form={form}
              onFinish={handleSubmit}
              layout="vertical"
            >
              <Heading as="h3">LOGIN TO YOUR ACCOUNT</Heading>
              <Form.Item
                name="email"
                rules={[
                  {
                    message: 'Please input your Email!',
                    required: true,
                  },
                ]}
                label="Email Address"
              >
                <Input
                  data-credentials="email"
                  onChange={credentialChange}
                  placeholder="Enter your email address"
                />
              </Form.Item>
              <Form.Item name="password" label="Password">
                <Input.Password
                  data-credentials="password"
                  onChange={credentialChange}
                  placeholder="password"
                />
              </Form.Item>
              <div className="auth-form-action">
                <NavLink className="forgot-pass-link" to="/forgotPassword">
                  Forgot password?
                </NavLink>
              </div>
              <Form.Item>
                <Button
                  className="btn-signin"
                  htmlType="submit"
                  type="primary"
                  size="large"
                >
                  {isLoading ? 'Loading...' : 'Sign In'}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </AuthWrapper>
  )
}

export default AuthLayout(SignIn)
