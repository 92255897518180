const actions = {
  GET_ALL_ITEMS: 'GET_ALL_ITEMS',
  GET_ALL_PURCHASES: 'GET_ALL_PURCHASES',
  GET_CART_ITEMS: 'GET_CART_ITEMS',


  getAllItems: result => ({
    type: actions.GET_ALL_ITEMS,
    data: result,
  }),
  getAllPurchases: result => ({
    type: actions.GET_ALL_PURCHASES,
    data: result,
  }),
  getCartItems: result => ({
    type: actions.GET_CART_ITEMS,
    data: result,
  }),
  
}

export default actions
