import {updateError} from './actions';


const handleUpdateError = (errorMessage) => async dispatch => {
  dispatch(updateError(errorMessage));
}

export {
  handleUpdateError
}
