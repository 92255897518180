import actions from './actions'
import axiosInstance from '../../axiosApi'

const { getAllApprovals } = actions

const handleGetAllApprovalss = () => async dispatch => {
  axiosInstance
    .get('approvals/get_all/')
    .then(res => {
      dispatch(getAllApprovals(res))
    })
    .catch(err => null)
}

const handleApproveApprovals = (id, type, points = 0, qrcode=0) => async dispatch => {
  axiosInstance
    .get(
      `/approvals/change_status/?id=${id}&status=approved&type=${type}&points=${points}&qrcode=${qrcode}`,
    )
    .then(res => {
      dispatch(handleGetAllApprovalss())
    })
    .catch(err => null)
}

const handleDenyApprovals = (id, type, qrcode) => async dispatch => {
  axiosInstance
    .get(`/approvals/change_status/?id=${id}&status=denied&type=${type}&qrcode=${qrcode}`)
    .then(res => {
      dispatch(handleGetAllApprovalss())
    })
    .catch(err => null)
}
export { handleGetAllApprovalss, handleApproveApprovals, handleDenyApprovals }
