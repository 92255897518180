import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL_API

const axiosInstance = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    Authorization: localStorage.getItem('access_token')
      ? `JWT ${localStorage.getItem('access_token')}`
      : null,
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
})

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config

    // Prevent infinite loops
    if (
      error.response.status === 401 &&
      originalRequest.url === `${baseURL}/user/token/refresh/`
    ) {
      window.location.href = '/'
      return Promise.reject(error)
    }

    if (
      error.response.data.code === 'token_not_valid' &&
      error.response.status === 401 &&
      error.response.statusText === 'Unauthorized'
    ) {
      const refreshToken = localStorage.getItem('refresh_token')

      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]))

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000)

        if (tokenParts.exp > now) {
          return axiosInstance
            .post('/user/token/refresh/', { refresh: refreshToken })
            .then(response => {
              localStorage.setItem('access_token', response.data.access)
              localStorage.setItem('refresh_token', response.data.refresh)

              axiosInstance.defaults.headers.Authorization = `JWT ${response.data.access}`
              originalRequest.headers.Authorization = `JWT ${response.data.access}`

              return axiosInstance(originalRequest)
            })
            .catch(err => null)
        }
        window.location.href = '/'
      } else {
        window.location.href = '/'
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error)
  },
)

export default axiosInstance
