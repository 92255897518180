import actions from './actions'

const initialState = {
  allItems: [],
  allPurchases: {},
  allCartItems: [],
}
const storeReducer = (state = initialState, action) => {
  const { type, data, err } = action
  let newState
  switch (type) {
    case actions.GET_ALL_ITEMS:
      newState = Object.assign(state.allItems, data.data)
      return {
        ...state,
        allItems: [...data.data],
      }
      break
    case actions.GET_ALL_PURCHASES:
      newState = Object.assign(state.allPurchases, data.data)
      return {
        ...state,
        newState,
      }
      break
    case actions.GET_CART_ITEMS:
      return {
        ...state,
        allCartItems: data.data,
      }
      break
    default:
      return state
  }
}

export { storeReducer }
