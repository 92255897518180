import React from 'react'
import {Result, Button} from 'antd'
import { useHistory } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { AuthWrapper } from './style'

const ResetPasswordUpdateSuccess = () => {
  const history = useHistory()

  return (
    <AuthWrapper>
        <Result
           status="success"
            title="Password Reset Successful"
            subTitle="Your Password Has Successfully Been Updated"
          extra={
            <Button
              type="primary"
              onClick={() => history.push('/login')}
            >
              Return To Login
            </Button>
          }
        />

      </AuthWrapper>
  )
}

export default ResetPasswordUpdateSuccess
