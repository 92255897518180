import actions from './actions'

const {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR,
} = actions

const initState = {
  login: !!localStorage.getItem('access_token') || false,
  loading: false,
  error: null,
  success: false,
}

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
        error: false,
        success: true,
      }
    case LOGIN_ERR:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
      }
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        login: data,
      }
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
        error: true,
        success: false,
      }
    default:
      return state
  }
}
export default AuthReducer
