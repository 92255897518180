import React, { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Form, Input, Button, Spin, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { AuthWrapper } from './style'
import Heading from '../../../../components/heading/heading'
import { getResetToken, togglePasswordTokenSuccess } from '../../../../redux/PasswordReset/actionCreator'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const {
    password_token_success,
    password_token_loading,
    password_token_error
  } = useSelector(state => ({
    password_token_success: state.passwordReset.password_token_success,
    password_token_loading: state.passwordReset.password_token_loading,
    password_token_error: state.passwordReset.password_token_error,
  }))
  const [form] = Form.useForm()
  const handleSubmit = values => {
    if (values.email) {
      dispatch(getResetToken(values.email.toLowerCase()))

      form.resetFields()
    }
  }
  const error = () => {
        message.error('There was an error resetting your password. Please try again or contact your admin.');
  };
  useEffect(() => {
      dispatch(togglePasswordTokenSuccess(false))
  }, [])
   useEffect(() => {
     if (password_token_error){
        error()
     }
  }, [password_token_error])
  const history = useHistory();
  useEffect(() => {
     if (password_token_success){
        history.push("/password-reset-success");
     }
  }, [password_token_success])
  return (
    <AuthWrapper>

      <Spin spinning={password_token_loading} key={password_token_loading}>
      <div className="auth-contents">
        <Form
          name="forgotPass"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Heading as="h3">Forgot Password?</Heading>
          <p className="forgot-text">
            Enter the email address you used when you joined and we’ll send you
            instructions to reset your password.
          </p>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
                type: 'email',
              },
            ]}
          >
            <Input placeholder="name@example.com" />
          </Form.Item>
          <Form.Item>
            <Button
              className="btn-reset"
              htmlType="submit"
              type="primary"
              size="large"
            >
              Send Reset Instructions
            </Button>
          </Form.Item>
          <p className="return-text">
            Return to <NavLink to="/login">Sign In</NavLink>
          </p>
        </Form>
      </div>
      </Spin>
    </AuthWrapper>
  )
}

export default ForgotPassword
