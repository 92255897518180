const actions = {
  GET_ALL_SHOUTOUTS: 'GET_ALL_SHOUTOUTS',
  GET_ALL_SHOUTOUTS_LOADING: 'GET_ALL_SHOUTOUTS_LOADING',
  GET_ALL_SHOUTOUTS_SUCCESS: 'GET_ALL_SHOUTOUTS_SUCCESS',
  GET_ALL_SHOUTOUTS_ERROR: 'GET_ALL_SHOUTOUTS_ERROR',
  GET_SHOUTOUT_POST_BY_ID: 'GET_SHOUTOUT_POST_BY_ID',
  GET_ALL_SHOUTOUT_TYPE: 'GET_ALL_SHOUTOUT_TYPE',
  GET_ALL_CORE_VALUES: 'GET_ALL_CORE_VALUES',
  SHOUT_OUT_ERROR: 'SHOUT_OUT_ERROR',
  GROUP_SHOUTOUT_LOADING:'GROUP_SHOUTOUT_LOADING',
  GROUP_SHOUTOUT_SUCCESS:'GROUP_SHOUTOUT_SUCCESS',
  GROUP_SHOUTOUT_ERROR:'GROUP_SHOUTOUT_ERROR',

  getallshoutouts: result => ({
    type: actions.GET_ALL_SHOUTOUTS,
    data: result,
  }),
  getallshoutoutsLoading: result => ({
    type: actions.GET_ALL_SHOUTOUTS_LOADING,
    data: result,
  }),
  getallshoutoutsSuccess: result => ({
    type: actions.GET_ALL_SHOUTOUTS_SUCCESS,
    data: result,
  }),
  getallshoutoutsError: result => ({
    type: actions.GET_ALL_SHOUTOUTS_ERROR,
    data: result,
  }),
  getShoutOUtPostById: result => ({
    type: actions.GET_SHOUTOUT_POST_BY_ID,
    data: result,
  }),

  getAllShoutOuTtypes: result => ({
    type: actions.GET_ALL_SHOUTOUT_TYPE,
    data: result,
  }),
  getAllCoreValues: result => ({
    type: actions.GET_ALL_CORE_VALUES,
    data: result,
  }),
  setShoutOutError: result => ({
    type: actions.SHOUT_OUT_ERROR,
    data: result,
  }),groupShoutOutLoading: result => ({
    type: actions.GROUP_SHOUTOUT_LOADING,
    data: result,
  }),groupShoutOutSuccess: result => ({
    type: actions.GROUP_SHOUTOUT_SUCCESS,
    data: result,
  }),groupShoutOutError: result => ({
    type: actions.GROUP_SHOUTOUT_ERROR,
    data: result,
  }),



}

export default actions
