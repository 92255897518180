import { combineReducers } from 'redux'
import authReducer from './authentication/reducers'
import ChangeLayoutMode from './themeLayout/reducers'
import { userReducer } from './users/reducers'
import { shoutoutReducer } from './shoutouts/reducers'
import { errorHandlingReducer } from './errorHandling/reducers'
import { NotificationReducer } from './notification/reducers'
import ApprovalsReducer from './approvals/reducers'
import { qrCodeReducer } from './qrcode/reducers'
import { storeReducer } from './store/reducers'
import { pointScheduleReducer } from './pointManagement/reducers'

import PasswordResetReducer from './PasswordReset/reducers'

const rootReducers = combineReducers({
  error: errorHandlingReducer,
  allshoutouts: shoutoutReducer,
  notification: NotificationReducer,
  approvals: ApprovalsReducer,
  qrcode: qrCodeReducer,
  storeData: storeReducer,
  users: userReducer,
  auth: authReducer,
  pointSchedules: pointScheduleReducer,
  ChangeLayoutMode,
  passwordReset: PasswordResetReducer,
})

export default rootReducers
