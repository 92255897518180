import actions from './actions'

const { REQUEST_TOKEN, VALIDATE_TOKEN, TOGGLE_RESET_SUCCESS, PASSWORD_TOKEN_SUCCESS, TOGGLE_PASSWORD_TOKEN_LOADING,
  TOGGLE_PASSWORD_TOKEN_ERROR, TOGGLE_PASSWORD_TOKEN_SUCCESS, TOGGLE_PASSWORD_UPDATE_SUCCESS,
  TOGGLE_PASSWORD_UPDATE_LOADING, TOGGLE_PASSWORD_UPDATE_ERROR } = actions

const initState = {
  loading: false,
  error: null,
  success: false,
  password_token_success: false,
  password_token_loading: false,
  password_update_loading: false,
  password_update_error: false,
  password_update_error_message: '',
  password_update_success: false,
  password_token_error: null
}

/**
 *
 * @todo impure state mutation/explaination
 */
const PasswordResetReducer = (state = initState, action) => {
  const { type, data } = action
  switch (type) {
    case REQUEST_TOKEN:
      return {
        ...state,
      }
    case VALIDATE_TOKEN:
      return {
        ...state,
      }
    case TOGGLE_RESET_SUCCESS:
      return {
        ...state,
        success: !state.success,
      }
    case TOGGLE_PASSWORD_TOKEN_LOADING:
      return {
        ...state,
        password_token_loading: data,
      }
      case TOGGLE_PASSWORD_TOKEN_ERROR:
      return {
        ...state,
        password_token_error: data,
      }
      case TOGGLE_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        password_token_success: data,
      }
      case TOGGLE_PASSWORD_UPDATE_LOADING:
      return {
        ...state,
        password_update_success: false,
        password_update_loading: true,
        password_update_error: false,
        password_update_error_message: ''
      }
case TOGGLE_PASSWORD_UPDATE_ERROR:
      return {
        ...state,
        password_update_error: true,
        password_update_loading: false,
        password_update_error_message: data
      }
case TOGGLE_PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        password_update_success: true,
        password_update_loading: false,
        password_update_error_message: ''

      }

    default:
      return state
  }
}
export default PasswordResetReducer
