import React from 'react'
import propTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ProtectedRoute = ({ children, ...rest }) => {
  const isLoggedIn = useSelector(state => state.auth.login)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

ProtectedRoute.propTypes = {
  children: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
}

export default ProtectedRoute
