const actions = {
  // TODO Add current crew action string variable
  GET_ALL_POINT_SCHEDULES: 'GET_ALL_POINT_SCHEDULES',
  SET_POINT_SCHEDULE: 'SET_POINT_SCHEDULE',
  REMOVE_POINT_SCHEDULE: 'REMOVE_POINT_SCHEDULE',
  REFRESH_POINT_SCHEDULE: 'REFRESH_POINT_SCHEDULE',

  // TODO add current crew action function
  getAllPointSchedules: result => ({
    type: actions.GET_ALL_POINT_SCHEDULES,
    data: result,
  }),
  setPointSchedule: result => ({
    type: actions.SET_POINT_SCHEDULE,
    data: result,
  }),
  removePointSchedule: result => ({
    type: actions.REMOVE_POINT_SCHEDULE,
    data: result,
  }),
}

export default actions
