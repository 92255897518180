import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { Form, Input, Button, Spin } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { AuthWrapper } from './style'
import Heading from '../../../../components/heading/heading'
import { getResetToken } from '../../../../redux/PasswordReset/actionCreator'
import { HandleValidateQRCodeUser } from '../../../../redux/qrcode/actionCreator'
import { message, Space } from 'antd';

const Validate = () => {

  const {
    validatePending,
    validateError,
    validateSuccess,
    validateErrorMessage

  } = useSelector(
    state => ({
      validatePending: state.qrcode.validatePending,
      validateError: state.qrcode.validateError,
      validateSuccess: state.qrcode.validateSuccess,
      validateErrorMessage: state.qrcode.validateErrorMessage
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (validateError){
      message.error(validateErrorMessage, 5);
    }
  }, [validateError])

  useEffect(() => {
    if (validateSuccess){
      message.success("Your request was successful. Please check your email for a link to complete redeeming this qr code.", 5);
    }
  }, [validateSuccess])

  const dispatch = useDispatch()
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuery()
  const [form] = Form.useForm()
  const handleSubmit = values => {
    if (values.email) {
      const token = query.get('token')
      dispatch(HandleValidateQRCodeUser(token, (values.email).toLowerCase()))

      form.resetFields()
    }
  }
  return (
    <AuthWrapper>
       <div key={validatePending}>
      {validatePending ? (
        <div className="spin">
          <Space size="middle">
            <Spin size="large" />
          </Space>
        </div>) : (
      <div className="auth-contents">
        <Form
          name="forgotPass"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Heading as="h3">Validate QR Code</Heading>
          <p className="forgot-text">
            Enter your email address for the application to redeem your award
            points.
          </p>
          <Form.Item
            label="Email Address"
            name="email"

            rules={[
              {
                required: true,
                message: 'Please input your email!',
                type: 'email',
              },
            ]}
          >
            <Input type="email" placeholder="name@example.com" />
          </Form.Item>
          <Form.Item>
            <Button
              className="btn-reset"
              htmlType="submit"
              type="primary"
              size="large"
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </div>)}
      </div>
    </AuthWrapper>
  )
}

export default Validate
