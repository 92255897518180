const actions = {
  CHANGE_LAYOUT_MODE_BEGIN: 'CHANGE_LAYOUT_MODE_BEGIN',
  CHANGE_LAYOUT_MODE_SUCCESS: 'CHANGE_LAYOUT_MODE_SUCCESS',
  CHANGE_LAYOUT_MODE_ERR: 'CHANGE_LAYOUT_MODE_ERR',

  CHANGE_RTL_MODE_BEGIN: 'CHANGE_RTL_MODE_BEGIN',
  CHANGE_RTL_MODE_SUCCESS: 'CHANGE_RTL_MODE_SUCCESS',
  CHANGE_RTL_MODE_ERR: 'CHANGE_RTL_MODE_ERR',

  CHANGE_MENU_MODE_BEGIN: 'CHANGE_MENU_MODE_BEGIN',
  CHANGE_MENU_MODE_SUCCESS: 'CHANGE_MENU_MODE_SUCCESS',
  CHANGE_MENU_MODE_ERR: 'CHANGE_MENU_MODE_ERR',

  changeLayoutBegin: () => ({
      type: actions.CHANGE_LAYOUT_MODE_BEGIN
    }),

  changeLayoutSuccess: data => ({
      type: actions.CHANGE_LAYOUT_MODE_SUCCESS,
      data
    }),

  changeLayoutErr: err => ({
      type: actions.CHANGE_LAYOUT_MODE_ERR,
      err
    }),

  changeRtlBegin: () => ({
      type: actions.CHANGE_RTL_MODE_BEGIN
    }),

  changeRtlSuccess: data => ({
      type: actions.CHANGE_RTL_MODE_SUCCESS,
      data
    }),

  changeRtlErr: err => ({
      type: actions.CHANGE_RTL_MODE_ERR,
      err
    }),

  changeMenuBegin: () => ({
      type: actions.CHANGE_MENU_MODE_BEGIN
    }),

  changeMenuSuccess: data => ({
      type: actions.CHANGE_MENU_MODE_SUCCESS,
      data
    }),

  changeMenuErr: err => ({
      type: actions.CHANGE_MENU_MODE_ERR,
      err
    })
}

export default actions
