import {actions} from './actions'
const initialState = {
  error: { 
    message: '',
  }
}
const errorHandlingReducer = (state = initialState, action) => {
  const { type, data, err } = action
  let newState;

  switch (type) {
    case actions.UPDATE_ERROR: 
      return { 
        ...state,
        error: { 
          message: data
        }
      };
    default:
      return state
  }
}
export { errorHandlingReducer }
