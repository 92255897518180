import actions from './actions'

const initialState = {
  allShoutOuts: {},
  allShoutOutTypes: [],
  allCoreValue: [],
  shoutOutError: false,
  getAllShoutoutsLoading:true,
  getAllShoutoutsSuccess:false,
  getAllShoutoutsError:false,
  groupShoutoutsLoading:false,
  groupShoutoutsSuccess:false,
  groupShoutoutsError:false,
  groupShoutoutsErrorMessage:'',
  currentShoutOut: {
    core_values: [],
    user_from: {
      first_name: '',
      last_name: '',
    },
    datetime_created: new Date(),
    id: undefined,
    shout_out_type: {},
    shout_out_text: '',
    comments: [],
    like_count: 0,
    likes: [],
    user_to: {},
  },
  shoutOutErrorMessage: '',
}
const shoutoutReducer = (state = initialState, action) => {
  const { type, data } = action

  let newState
  switch (type) {
    case actions.GET_ALL_SHOUTOUTS:
      newState = Object.assign(state.allShoutOuts, data.data)
      return {
        ...state,
        shoutOutError: false,
      }
       case actions.GET_ALL_SHOUTOUTS_LOADING:

      return {
        ...state,
        getAllShoutoutsLoading: data,
      }

       case actions.GET_ALL_SHOUTOUTS_SUCCESS:
      newState = Object.assign(state.allShoutOuts, data.data)
      return {
        ...state,
        getAllShoutoutsSuccess: data,
      }

       case actions.GET_ALL_SHOUTOUTS_ERROR:
      newState = Object.assign(state.allShoutOuts, data.data)
      return {
        ...state,
        getAllShoutoutsError: data,
      }

    case actions.GET_SHOUTOUT_POST_BY_ID:
      return {
        ...state,
        currentShoutOut: data.data,
      }

    case actions.GET_ALL_SHOUTOUT_TYPE:
      newState = Object.assign(state.allShoutOutTypes, data.data)
      return {
        ...state,
        newState,
      }

    case actions.GET_ALL_CORE_VALUES:
      newState = Object.assign(state.allCoreValue, data.data)
      return {
        ...state,
        newState,
      }

    case actions.SHOUT_OUT_ERROR:
      return {
        ...state,
        shoutOutError: data[1],
        shoutOutErrorMessage: data[0],
      }
    case actions.GROUP_SHOUTOUT_LOADING:
          return {
            ...state,
            groupShoutoutsLoading: data,
            groupShoutoutsSuccess: false,
            groupShoutoutsError: false,
            groupShoutoutsErrorMessage: ''
          }
    case actions.GROUP_SHOUTOUT_SUCCESS:
          return {
            ...state,
            groupShoutoutsLoading: false,
            groupShoutoutsSuccess: data,
            groupShoutoutsError: false,
            groupShoutoutsErrorMessage: ''
          }
    case actions.GROUP_SHOUTOUT_ERROR:
          return {
            ...state,
            groupShoutoutsLoading: false,
            groupShoutoutsSuccess: false,
            groupShoutoutsError: data.status,
            groupShoutoutsErrorMessage: data.message
          }

    default:
      return state
  }
}

export { shoutoutReducer }
