import React from 'react'
import ReactDOM from 'react-dom'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ru from 'javascript-time-ago/locale/ru'
import * as serviceWorker from './serviceWorker'
import App from './App'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn:
    'https://df225239bbdb4b96a194ac45a8ca732c@o270928.ingest.sentry.io/5884845',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [`${process.env.REACT_APP_API_URL_API}`],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})
TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)
// import mockAxios from './__mocks__/mockAxios';

// mockAxios(axios);

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
