import actions from './actions'

const initialState = {
  pointSchedules: [],
}
const pointScheduleReducer = (state = initialState, action) => {
  const { type, data } = action
  let newState
  switch (type) {
    case actions.GET_ALL_POINT_SCHEDULES:
      newState = data.data
      return {
        ...state,
        pointSchedules: newState,
      }
    case actions.REMOVE_POINT_SCHEDULE:
      newState = data.data
      return {
        ...state,
        pointSchedules: newState,
      }

    default:
      return state
  }
}

export { pointScheduleReducer }
