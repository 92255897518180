import React from 'react'
import { Row, Col } from 'antd'
import { Aside, Content } from './overview/style'
import logo from '../../../static/img/auth/ekLogo.png'

const AuthLayout = WraperContent => () => (
  <Row>
    <Col xxl={8} xl={9} lg={12} md={12} xs={24}>
      <Aside
        style={{
          textAlign: 'center',
          background:
            'linear-gradient(180deg, #EC1C24 0%, rgba(0, 0, 0, 0.86) 99.96%, #000000 99.97%, #080001 99.98%, rgba(5, 0, 0, 0.0208333) 99.99%)',
          paddingTop: '30%',
        }}
      >
        <div className="auth-side-content">
          <Content>
            <br />
            <br />
            <img style={{ width: '100%', marginBottom: '3em' }} src={logo} />
            <h1
              style={{ color: '#fff', fontSize: '30px', fontWeight: 'bolder' }}
            >
              TAKE FIVE STAY ALIVE
            </h1>
          </Content>
        </div>
      </Aside>
    </Col>

    <Col xxl={16} xl={15} lg={12} md={12} xs={24}>
      <WraperContent />
    </Col>
  </Row>
)

export default AuthLayout
