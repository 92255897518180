import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { Form, Input, Button, Result, Space, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { AuthWrapper } from './style'
import Heading from '../../../../components/heading/heading'
import { getResetToken } from '../../../../redux/PasswordReset/actionCreator'
import {
  HandleValidateQRCodeUser,
  redeemQRCode,
} from '../../../../redux/qrcode/actionCreator'
import { logOut } from '../../../../redux/authentication/actionCreator'

const Redeem = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()

  const {
    redeemLoading,
    redeemSuccess,
    redeemError,
    redeemAlreadyRedeemed,
  } = useSelector(state => ({
    redeemLoading: state.qrcode.redeemLoading,
    redeemSuccess: state.qrcode.redeemSuccess,
    redeemError: state.qrcode.redeemError,
    redeemAlreadyRedeemed: state.qrcode.redeemAlreadyRedeemed,
  }))
  useEffect(() => {
    const token = query.get('token')
    if (token) {
      dispatch(redeemQRCode(token))
    }
  }, [])

  const SignOut = () => {
    dispatch(logOut())
    history.push('/login')
  }

  return (
    <div key={redeemSuccess}>
      {redeemLoading ? (
        <div className="spin">
          <Space size="middle">
            <Spin size="large" />
          </Space>
        </div>
      ) : redeemError ? (
        <Result
          status="warning"
          title="There was are error redeeming your points."
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => history.push('/admin')}
            >
              Go Home
            </Button>,
            <Button key="buy " onClick={SignOut}>
              Log Out
            </Button>,
          ]}
        />
      ) : redeemAlreadyRedeemed ? (
        <Result
          status="info"
          title="You already redeemed this award"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => history.push('/admin')}
            >
              Go Home
            </Button>,
            <Button key="buy " onClick={SignOut}>
              Log Out
            </Button>,
          ]}
        />
      ) : (
        <Result
          status="success"
          title="You've Redeemed your Points"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => history.push('/admin')}
            >
              Go Home
            </Button>,
            <Button key="buy " onClick={SignOut}>
              Log Out
            </Button>,
          ]}
        />
      )}
    </div>
  )
}

export default Redeem
