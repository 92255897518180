const actions = {
  GET_ALL_SHOUTOUTS: 'GET_ALL_SHOUTOUTS',
  GET_ALL_SHOUTOUT_TYPE: 'GET_ALL_SHOUTOUT_TYPE',
  GET_ALL_CORE_VALUES: 'GET_ALL_CORE_VALUES',
  GET_ALL_NOTIFICATIONS: 'GET_ALL_NOTIFICATIONS',

  getAllNotification: (result) => ({ 
    type: actions.GET_ALL_NOTIFICATIONS,
    data: result,
  })
}

export default actions
