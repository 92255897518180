import actions from './actions'
import axiosInstance from '../../axiosApi'
import { handleUpdateError } from '../errorHandling/actionCreator'

const {
  getallQRCode,
  generateQRCode,
  validateQRCodeUser,
  getGeneralQRCode,
  setRedeemLoading,
  setRedeemSuccess,
  setRedeemError,
  setAlreadyRedeemed,
  setValidatePending,
  setValidateSuccess,
  setValidateError
} = actions

const HandlegetAllQRcode = () => async dispatch => {
  axiosInstance
    .get('qrcode/get_current_user_qrcodes/')
    .then(res => {
      console.log(res);
      dispatch(getallQRCode(res))
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error getting your QRCodes, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const handleGeneralQRCode = () => async dispatch => {
  axiosInstance
    .get('qrcode/list_all_qr_codes/')
    .then(res => {
      dispatch(getGeneralQRCode(res))
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error retrieving all QRCodes, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const handleUpdateQRCode = newQRCodeObj => async dispatch => {
  axiosInstance
    .put('qrcode/update_qr_code_type/', newQRCodeObj)
    .then(res => {
      dispatch(handleGeneralQRCode())
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error updating this QRCodes, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const HandlegenerateQRCode = QRCodeid => async dispatch => {
  axiosInstance
    .get(`qrcode/get_qrcode?qr_code_id=${QRCodeid}`)
    .then(result => {
      dispatch(generateQRCode(result))
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error generating this QRCode, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const HandleValidateQRCodeUser = (token, email) => async dispatch => {
  dispatch(setValidatePending())
  axiosInstance
    .post('qrcode/validate_user/', { token, email })
    .then(async res => {
      dispatch(setValidateSuccess(true))
      dispatch(validateQRCodeUser())
      dispatch(setValidateSuccess(false))
    })
    .catch(error => {
      dispatch(setValidateError(error.response.data))
    })
}

const HandleValidateQRCodeUserEmail = (token, id) => async dispatch => {
  dispatch(setValidatePending())
  axiosInstance
    .post('qrcode/validate_user_email/', { token, id })
    .then(async res => {
        dispatch(setValidateSuccess(true))
        dispatch(validateQRCodeUser())
        dispatch(setValidateSuccess(false))
    })
    .catch(error => {
      dispatch(setValidateError(error.response.data))
    })
}

const handleCreateQRCode = newQrcodeData => async dispatch => {
  axiosInstance
    .post('qrcode/create_qr_code_type/', newQrcodeData)
    .then(async res => {
      dispatch(handleGeneralQRCode())
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error creating this, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const deleteQRCode = coreValueID => async dispatch => {
  axiosInstance
    .delete(`qrcode/delete_qr_code?qr_code_id=${coreValueID}`)
    .then(res => {
      dispatch(handleGeneralQRCode())
    })
    .catch(error => {
      dispatch(
        handleUpdateError(
          'Error deleting this QRCode, please try again or contact your administrator',
        ),
      )
      setTimeout(() => {
        dispatch(handleUpdateError(''))
      }, 1000)
    })
}

const redeemQRCode = token => async dispatch => {
  dispatch(setRedeemLoading(true))
  dispatch(setRedeemSuccess(false))
  dispatch(setRedeemError(false))
  dispatch(setAlreadyRedeemed(false))
  axiosInstance
    .post(`qrcode/redeem_qr_code/`, { token })
    .then(res => {
      dispatch(setRedeemLoading(false))
      if (res.status === 208) {
        dispatch(setAlreadyRedeemed(true))
      } else {
        dispatch(setRedeemSuccess(true))
      }
    })
    .catch(error => {
      dispatch(setRedeemLoading(false))
      dispatch(setRedeemError(true))
    })
}

export {
  HandlegetAllQRcode,
  HandlegenerateQRCode,
  HandleValidateQRCodeUser,
  handleGeneralQRCode,
  handleUpdateQRCode,
  deleteQRCode,
  handleCreateQRCode,
  HandleValidateQRCodeUserEmail,
  redeemQRCode,
}
