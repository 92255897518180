import actions from './actions'

const initialState = {
  allApprovals: [],
}

const ApprovalsReducer = (state = initialState, action) => {
  const { type, data, err } = action
  let newState
  switch (type) {
    case actions.GET_ALL_APPROVALS:
      Object.assign(state.allApprovals, data.data)
      return {
        ...state,
      }
      break
    default:
      return state
  }
}

export default ApprovalsReducer
