import React, { lazy } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

const ShoutOut = lazy(() => import('../../container/pages/ShoutOuts/ShoutOut'))
const Usermanagement = lazy(() =>
  import('../../container/pages/UserManagement/UserManagement'),
)
const ShopManagementPage = lazy(() =>
  import('../../container/pages/ShopManagement/ShopManagementPage'),
)
const PointsManagement = lazy(() =>
  import('../../container/pages/PointsManagement/PointsManagement'),
)
const AwardManagement = lazy(() =>
  import('../../container/pages/AwardManagement/AwardManagement'),
)
const CrewManagement = lazy(() =>
  import('../../container/pages/CrewManagement/CrewManagement'),
)
const Shop = lazy(() => import('../../container/pages/Shop/Shop'))
const ApprovalPage = lazy(() =>
  import('../../container/pages/Approvals/ApprovalPage'),
)
const Cart = lazy(() => import('../../container/pages/Cart/Cart'))
const ProfilePage = lazy(() => import('../../container/pages/Profile/ProfilePage'))
const IndividualQRCode = lazy(() =>
  import('../../container/pages/IndividualQRCode'),
)
const NotFound = lazy(() => import('../../container/pages/404'))
const Maintenance = lazy(() => import('../../container/pages/Maintenance'))
const UploadPayrollComponent = lazy(() =>
  import('../../container/pages/UploadPayroll'),
)

const PagesRoute = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/404`} component={NotFound} />
      <Route path={`${path}/maintenance`} component={Maintenance} />
      <Route path={`${path}/shoutout`} component={ShoutOut} />
      <Route path={`${path}/usermanagement`} component={Usermanagement} />
      <Route path={`${path}/pointsmanagement`} component={PointsManagement} />
      <Route path={`${path}/awardmanagement`} component={AwardManagement} />
      <Route path={`${path}/crewmanagement`} component={CrewManagement} />
      <Route path={`${path}/shop`} component={Shop} />
      <Route path={`${path}/shopmanagement`} component={ShopManagementPage} />
      <Route path={`${path}/approvals`} component={ApprovalPage} />
      <Route path={`${path}/cart`} component={Cart} />
      <Route path={`${path}/profile/:tab?`} component={ProfilePage} />
      <Route path={`${path}/qrcodes/:id`} component={IndividualQRCode} />
      <Route path={`${path}/payroll`} component={UploadPayrollComponent} />
    </Switch>
  )
}

export default PagesRoute
