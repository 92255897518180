import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Form, Input, Button, Row, Col, Tag, Spin, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../../../components/heading/heading'
import { togglePasswordUpdateSuccess, validateTokenAndReset } from '../../../../redux/PasswordReset/actionCreator';

const NewPassword = () => {
  const history = useHistory()
  function useQuery() {
  return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  const {
    password_update_loading,
    password_update_success,
    password_update_error_message,
    password_update_error
  } = useSelector(state => ({
    password_update_loading: state.passwordReset.password_update_loading,
    password_update_success: state.passwordReset.password_update_success,
    password_update_error: state.passwordReset.password_update_error,
    password_update_error_message: state.passwordReset.password_update_error_message,
  }))

  const [passwords, setPasswords] = useState({ 
    newPassword: undefined,
    confirmNewPassword: undefined
  });
  const [showConfirmation, setShowConfirmation] = useState(false)


  const handlePasswordChange = (event, type) => {
    const passWord = event.target.value;

    switch(type) { 
      case 'newPass': 
        setPasswords({...passwords, newPassword: passWord})
        break;
      case 'confirmPass': 
        setShowConfirmation(true);
        setPasswords({...passwords, confirmNewPassword: passWord})
        break;
      default: return null
    }
  }
  const dispatch = useDispatch()
  const handleSubmit = () => {
    const token = query.get("token")
    dispatch(validateTokenAndReset(token, passwords.newPassword))
  }

  const error = () => {
        let message_string = password_update_error_message ? password_update_error_message :
          'There was an error resetting your password. Please try again or contact your admin.'

        message.error(message_string);
  };

   useEffect(() => {
     if (password_update_error){
        error()
     }
  }, [password_update_error])

  useEffect(() => {
     if (password_update_success){
        history.push("/password-update-success");
     }
  }, [password_update_success])

  return (
      <Spin spinning={password_update_loading} key={password_update_loading}>
    <Row>
      <Col
        span={24}
      >
        <div
          className="auth-contents"
          style={{width: '65%', margin: '0 auto', padding: '4rem 0'}}
        >
          <Form name="forgotPass" onFinish={handleSubmit} layout="vertical">
            <Heading as="h3">New Password</Heading>
            <p className="forgot-text">
              Enter your new password. 
            </p>
            <Form.Item
              label="New Password"
              name="hidden"
              required
            >
              <Input 
                onChange={(event) => {
                  handlePasswordChange(event, 'newPass')
                }}
                value={passwords.newPassword}
                required
                type='password'
                placeholder="secure password"
                min={6} 
              />
            </Form.Item>

            <Form.Item
              label="Confirm new password"
              name="password"
              hasFeedback

              validateTrigger='onChange'
              validateStatus={passwords.newPassword === passwords.confirmNewPassword ? (!passwords.confirmNewPassword ? 'validating' : 'success') : 'error'}
              help={passwords.newPassword === passwords.confirmNewPassword ? (!passwords.confirmNewPassword ? '' : 'Great! the provided passwords match') : 'Ooops, the provided passwords do not match'}
            >
              <Input 
                onChange={(event) => {
                  handlePasswordChange(event, 'confirmPass')
                }}
                value={passwords.confirmNewPassword}
                required
                type='password'
                placeholder="secure password" 
              />
            </Form.Item>

            <Form.Item>
              <Button 
                style={{marginTop: '2rem'}}
                className="btn-reset" 
                htmlType="submit" 
                type="primary" 
                size="large"
                disabled={passwords.newPassword !== passwords.confirmNewPassword}
              >
                Save new password
              </Button>
            </Form.Item>
            <p className="return-text">
              Return to <NavLink to="/">Sign In</NavLink>
            </p>
          </Form>
        </div>
      </Col>
    </Row>
      </Spin>
  )
}
export default NewPassword;