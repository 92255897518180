import { theme, darkTheme } from './theme/themeVariables'

const config = {
  darkMode: false,
  topMenu: true,
  rtl: false,
  theme,
  darkTheme
}

export default config
