const actions = {
  REQUEST_TOKEN: 'REQUEST_TOKEN',
  VALIDATE_TOKEN: 'VALIDATE_TOKEN',
  TOGGLE_RESET_SUCCESS: 'TOGGLE_RESET_SUCCESS',
  TOGGLE_PASSWORD_TOKEN_LOADING: 'TOGGLE_PASSWORD_TOKEN_LOADING',
  TOGGLE_PASSWORD_TOKEN_ERROR: 'TOGGLE_PASSWORD_TOKEN_ERROR',
  TOGGLE_PASSWORD_TOKEN_SUCCESS: 'TOGGLE_PASSWORD_TOKEN_SUCCESS',
  TOGGLE_PASSWORD_UPDATE_LOADING: 'TOGGLE_PASSWORD_UPDATE_LOADING',
  TOGGLE_PASSWORD_UPDATE_ERROR: 'TOGGLE_PASSWORD_UPDATE_ERROR',
  TOGGLE_PASSWORD_UPDATE_SUCCESS: 'TOGGLE_PASSWORD_UPDATE_SUCCESS',

  requestToken: result => ({
    type: actions.REQUEST_TOKEN,
    data: result,
  }),
  validateToken: result => ({
    type: actions.VALIDATE_TOKEN,
    data: result,
  }),
  toggleSuccess: result => ({
    type: actions.TOGGLE_RESET_SUCCESS,
    data: result,
  }),
  togglePasswordTokenLoading: result => ({
    type: actions.TOGGLE_PASSWORD_TOKEN_LOADING,
    data: result,
  }),togglePasswordTokenError: result => ({
    type: actions.TOGGLE_PASSWORD_TOKEN_ERROR,
    data: result,
  }),togglePasswordTokenSuccess: result => ({
    type: actions.TOGGLE_PASSWORD_TOKEN_SUCCESS,
    data: result,
  }),togglePasswordUpdateLoading: result => ({
    type: actions.TOGGLE_PASSWORD_UPDATE_LOADING,
    data: result,
  }),togglePasswordUpdateError: result => ({
    type: actions.TOGGLE_PASSWORD_UPDATE_ERROR,
    data: result,
  }),togglePasswordUpdateSuccess: result => ({
    type: actions.TOGGLE_PASSWORD_UPDATE_SUCCESS,
    data: result,
  }),
}

export default actions
