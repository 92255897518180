import actions from './actions'
import axiosInstance from '../../axiosApi'

const { requestToken, validateToken, toggleSuccess, togglePasswordTokenLoading,
  togglePasswordTokenError, togglePasswordTokenSuccess, togglePasswordUpdateLoading,
  togglePasswordUpdateError, togglePasswordUpdateSuccess} = actions

const getResetToken = email => async dispatch => {
  dispatch(togglePasswordTokenLoading(true))
  axiosInstance
    .post(`/reset-password`, { email })
    .then(res => {
      dispatch(requestToken(false))
      dispatch(togglePasswordTokenLoading(false))
      dispatch(togglePasswordTokenSuccess(true))
    })
    .catch(err => {
      dispatch(requestToken(true))
      dispatch(togglePasswordTokenLoading(false))
      dispatch(togglePasswordTokenError(true))
    }
    )
}

const validateTokenAndReset = (token, newPass) => async dispatch => {
  dispatch(togglePasswordUpdateLoading(true))
  axiosInstance
    .post(`/reset-password/submit?token=${token}`, { password: newPass })
    .then(res =>{
      dispatch(togglePasswordUpdateSuccess(true))
      dispatch(validateToken(false))
    })
    .catch(err => {
      let message = err.response.data === 'Same password was set before' ? 'This password was used previously please enter a password that you haven\'t used': ''
      dispatch(togglePasswordUpdateError(message))
      dispatch(validateToken(true))

    }
    )
}
const ToggleSuccess = () => async dispatch => {
  dispatch(toggleSuccess({}))
}
const HandlePasswordReset = newPass => async dispatch => {
  axiosInstance
    .put(`/password/reset_password/`, { password: newPass })
    .then(res => {
      dispatch(toggleSuccess(res))
    })
    .catch(err => {})
}

export {
  getResetToken,
  validateTokenAndReset,
  HandlePasswordReset,
  ToggleSuccess,
  togglePasswordTokenSuccess,
  togglePasswordUpdateSuccess
}
